'use client';

import ErrorBoundary from '@haaretz/s-error-boundary';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';

import type { HtzLinkProps } from '@haaretz/s-htz-link';

export interface BreadcrumbProps {
  /** The breadcrumb section name */
  children: string;
  /** The url to pass to the HtzLink */
  href: HtzLinkProps['href'];
}

function BreadcrumbClient({ href, children }: BreadcrumbProps) {
  const biAction = useBi();
  const breadcrumbOnclick = () => {
    biAction({
      feature_type: 'content',
      feature: 'breadcrumbs',
      campaign_details: children,
      action_id: 153,
    });
  };
  return (
    <HtzLink href={href} onClick={breadcrumbOnclick}>
      {children}
    </HtzLink>
  );
}

export default function BreadcrumbClientWrapper({ children, ...props }: BreadcrumbProps) {
  return (
    <ErrorBoundary>
      <BreadcrumbClient {...props}>{children}</BreadcrumbClient>
    </ErrorBoundary>
  );
}
